import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  useToast,
  FormLabel,
  Input,
  Select,
  Button,
  Grid,
  Center,
  HStack,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
const NewBooking = () => {
  const genders = ["Male", "Female"];
  const [projectName, setProjectName] = useState("");
  const [blockName, setBlockname] = useState("");
  const [plotName, setPlotName] = useState("");
  const [registerygender, setregisterygender] = useState("");
  const [areaSqft, setAreaSqft] = useState();
  const [rateAreaSqft, setRateAreaSqft] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [discountApplicable, setDiscountApplicable] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [netAmount, setNetAmount] = useState(null);
  const [guidelineAmount, setGuidelineAmount] = useState();
  const [registryPercent, setRegistryPercent] = useState();
  const [registryAmount, setRegistryAmount] = useState();
  const [serviceAmount, setServiceAmount] = useState();
  const [maintenanceAmount, setMaintenanceAmount] = useState();
  const [miscAmount, setMiscAmount] = useState();
  const [contructionApplicable, setContructionApplicable] = useState();
  const [totalAmountPayable, setTotalAmountPayable] = useState();
  const [bankAmountPayable, setBankAmountPayable] = useState();
  const [cashAmountPayable, setCashAmountPayable] = useState();
  const [userRight, setUserRight] = useState(localStorage.getItem("userRight"));
  const [remarks, setRemarks] = useState("");
  const [contractorName, setContractorName] = useState("");
  const [constructionAmount, setConstructionAmount] = useState("");
  const [registry, setRegistry] = useState(100);
  const [grandTotal, setGrandTotal] = useState();
  const [projectsData, setprojectsData] = useState([]);
  const [blockData, setblockData] = useState([]);
  const [plotData, setplotData] = useState([]);
  const [contractorData, setcontractorData] = useState([]);
  const [master, setMaster] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [brokerData, setBrokerData] = useState([]);
  const toast = useToast();
  const [formData, setFormData] = useState({
    projectName: "",
    blockName: "",
    plotNo: "",
    plotType: "",
    customerName: "",
    customerAddress: "",
    customerContact: "",
    registryGender: "",
    areaSqft: "",
    rateAreaSqft: "",
    totalAmount: "",
    discountApplicable: "No",
    discountPercent: "",
    netAmount: "",
    registryAmount: "",
    serviceAmount: "",
    maintenanceAmount: "",
    miscAmount: "",
    grandTotal: "",
    constructionApplicable: "No",
    constructionContractor: "",
    totalAmountPayable: "",
    guidelineAmount: "",
    registryPercent: "",
    bankAmountPayable: "",
    cashAmountPayable: "",
    bookingDate: "",
    constructionAmount: "",
    remarks: "",
  });

  const updatePlotStatus = async () => {
    const url = "https://lkgexcel.com/backend_lms/setQuery.php";
    let query =
      "UPDATE plot SET plotStatus = 'Booked' WHERE plotNo = '" +
      plotName +
      "' AND projectName ='" +
      projectName +
      "' AND blockName  ='" +
      blockName +
      "' ;";

    let fData = new FormData();
    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
    } catch (error) {
      console.log(error.toJSON());
    }
  };

  const onAddBook = async () => {
    const projectName = document.getElementById("projectName").value;
    const blockName = document.getElementById("blockName").value;
    const plotNo = document.getElementById("plotNo").value;
    const customerName = document.getElementById("customerName").value;

    // Validate the required fields
    if (!projectName) {
      alert("Please fill in the project name");
      return;
    }

    if (!blockName) {
      alert("Please fill in the block name");
      return;
    }

    if (!plotNo) {
      alert("Please fill in the plot number");
      return;
    }

    if (!customerName) {
      alert("Please fill in the customer name");
      return;
    }

    setIsSubmitting(true);
    const url = "https://lkgexcel.com/backend_lms/setQuery.php";
    let query =
      "INSERT INTO booking (id, projectName, blockName, plotNo, plotType, customerName, customerAddress, customerContact, registryGender, areaSqft, rateAreaSqft, totalAmount, discountApplicable, discountPercent, netAmount, registryAmount, serviceAmount, maintenanceAmount, miscAmount, grandTotal, constructionApplicable, constructionContractor, constructionAmount, totalAmountPayable, guidelineAmount, registryPercent, bankAmountPayable, bookingDate, cashAmountPayable, broker, remarks) VALUES (NULL, '" +
      projectName +
      "', '" +
      blockName +
      "', '" +
      plotNo +
      "', '" +
      document.getElementById("plotType").value +
      "', '" +
      customerName +
      "', '" +
      document.getElementById("customerAddress").value +
      "', '" +
      document.getElementById("customerContact").value +
      "', '" +
      document.getElementById("registryGender").value +
      "', '" +
      document.getElementById("areaSqft").value +
      "', '" +
      document.getElementById("rateAreaSqft").value +
      "', '" +
      document.getElementById("totalAmount").value +
      "', '" +
      document.getElementById("discountApplicable").value +
      "', '" +
      document.getElementById("discountPercent").value +
      "', '" +
      document.getElementById("netAmount").value +
      "', '" +
      document.getElementById("registryAmount").value +
      "', '" +
      document.getElementById("serviceAmount").value +
      "', '" +
      document.getElementById("maintenanceAmount").value +
      "', '" +
      document.getElementById("miscAmount").value +
      "', '" +
      document.getElementById("grandTotal").value +
      "', '" +
      document.getElementById("constructionApplicable").value +
      "', '" +
      document.getElementById("constructionContractor").value +
      "', '" +
      document.getElementById("constructionAmount").value +
      "', '" +
      document.getElementById("totalAmountPayable").value +
      "', '" +
      document.getElementById("guidelineAmount").value +
      "', '" +
      document.getElementById("registryPercent").value +
      "', '" +
      document.getElementById("bankAmountPayable").value +
      "', '" +
      document.getElementById("bookingDate").value +
      "', '" +
      document.getElementById("cashAmountPayable").value +
      "', '" +
      document.getElementById("broker").value +
      "', '" +
      document.getElementById("remarks").value +
      "')";

    let fData = new FormData();
    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
      // console.log(response);
      window.location.reload();
      updatePlotStatus();
      toast({
        title: "Booking added successfully!",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: true,
      });

      // Clear form fields
      document.getElementById("projectName").value = "";
      document.getElementById("blockName").value = "";
      document.getElementById("plotNo").value = "";
      document.getElementById("plotType").value = "";
      document.getElementById("customerName").value = "";
      document.getElementById("customerAddress").value = "";
      document.getElementById("customerContact").value = "";
      document.getElementById("registryGender").value = "";
      document.getElementById("areaSqft").value = "";
      document.getElementById("rateAreaSqft").value = "";
      document.getElementById("totalAmount").value = "";
      document.getElementById("discountApplicable").value = "";
      document.getElementById("discountPercent").value = "";
      document.getElementById("netAmount").value = "";
      document.getElementById("registryAmount").value = "";
      document.getElementById("serviceAmount").value = "";
      document.getElementById("maintenanceAmount").value = "";
      document.getElementById("miscAmount").value = "";
      document.getElementById("grandTotal").value = "";
      document.getElementById("constructionApplicable").value = "";
      document.getElementById("constructionContractor").value = "";
      document.getElementById("constructionAmount").value = "";
      document.getElementById("totalAmountPayable").value = "";
      document.getElementById("guidelineAmount").value = "";
      document.getElementById("registryPercent").value = "";
      document.getElementById("bankAmountPayable").value = "";
      document.getElementById("bookingDate").value = "";
      document.getElementById("cashAmountPayable").value = "";
      document.getElementById("remarks").value = "";

      setIsSubmitting(false);
    } catch (error) {
      console.log(error.toJSON());
      setIsSubmitting(false);
    }
  };

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [phone, setPhone] = useState("");

  const handleInputChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "");
    if (formattedValue.length === 10) {
      setPhone(formattedValue);
    } else {
      setPhone(formattedValue);
    }
  };

  const loadBlocks = async (pname) => {
    let query = "SELECT * FROM block where projectName = '" + pname + "' ;";
    // alert(query);

    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();
    fData.append("query", query);
    try {
      const response = await axios.post(url, fData);
      if (response && response.data) {
        if (response.data.phpresult) {
          setblockData(response.data.phpresult);
          // console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadContractor = async () => {
    let query = "SELECT * FROM contractor;";
    // alert(query);
    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();
    fData.append("query", query);
    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setcontractorData(response.data.phpresult);
          // console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };
  const loadBroker = async () => {
    let query = "SELECT * FROM broker;";
    // alert(query);

    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setBrokerData(response.data.phpresult);
          // console.log(response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadPlots = async (bname) => {
    let query =
      "SELECT * FROM plot where blockName = '" +
      bname +
      "' AND projectName ='" +
      projectName +
      "' AND plotStatus ='Available' ;";
    // alert(query);

    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
      // console.log(response);

      if (response && response.data) {
        if (response.data.phpresult) {
          setplotData(response.data.phpresult);
          // console.log("Ploat DAta : ", response.data);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const loadProjects = async () => {
    let query = "SELECT * FROM project;";
    // alert(query);

    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();

    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);

      if (response && response.data) {
        if (response.data.phpresult) {
          setprojectsData(response.data.phpresult);
          // console.log("project data", response.data.phpresult);
        }
      }
    } catch (error) {
      console.log("Please Select Proper Input");
    }
  };

  const [myValue, setMyValue] = useState("");

  const onSelectPlot = async (pno) => {
    let query =
      "SELECT * FROM plot where blockName = '" +
      blockName +
      "' AND projectName ='" +
      projectName +
      "' AND plotStatus ='Available' AND plotNo='" +
      pno +
      "';";
    // alert(query);
    const url = "https://lkgexcel.com/backend_lms/getQuery.php";
    let fData = new FormData();
    fData.append("query", query);

    try {
      const response = await axios.post(url, fData);
      if (response && response.data) {
        if (response.data.phpresult) {
          // console.log("onselect phot No : ", response.data.phpresult);
          let query1 =
            "SELECT * FROM master where projectName ='" + projectName + "';";

          const url = "https://lkgexcel.com/backend_lms/getQuery.php";
          let fData1 = new FormData();

          fData1.append("query", query1);
          const response1 = await axios.post(url, fData1);
          if (response1 && response1.data) {
            if (response1.data.phpresult) {
              setMaster(response1.data?.phpresult);

              setregisterygender("Male");
              // console.log("MasterData", response1.data?.phpresult);

              setMyValue(response.data.phpresult[0]["plotType"]);

              const area = response.data.phpresult[0]["areaSqft"];
              setAreaSqft(area);
              const rate = response.data.phpresult[0]["ratePerSqft"];
              setRateAreaSqft(rate);
              const totalAmt =
                response.data.phpresult[0]["areaSqft"] *
                response.data.phpresult[0]["ratePerSqft"];
              setTotalAmount(totalAmt);
              const disApplicable =
                document.getElementById("discountApplicable").value;
              setDiscountApplicable(disApplicable);

              // console.log(discountApplicable);
              const disPercent =
                document.getElementById("discountPercent").value;
              setDiscountPercent(disPercent);

              if (document.getElementById("discountApplicable").value == "No") {
                const amt =
                  response.data.phpresult[0]["areaSqft"] *
                  response.data.phpresult[0]["ratePerSqft"];
                setNetAmount(amt);
              } else {
                const amt =
                  response.data.phpresult[0]["areaSqft"] *
                    response.data.phpresult[0]["ratePerSqft"] -
                  (response.data.phpresult[0]["areaSqft"] *
                    response.data.phpresult[0]["ratePerSqft"] *
                    document.getElementById("discountPercent").value) /
                    100;
                setNetAmount(amt);
              }

              document.getElementById("netAmount").value =
                document.getElementById("totalAmount").value;

              const guidelineAmt =
                response.data.phpresult[0]["areaSqmt"] *
                response1.data.phpresult[0]["guideline"];
              setGuidelineAmount(guidelineAmt);
              // console.log(guidelineAmount);

              if (document.getElementById("registryGender").value == "Male") {
                document.getElementById("registryPercent").value =
                  response1.data.phpresult[0]["registryMalePercent"];
                setRegistryPercent(
                  response1.data.phpresult[0]["registryMalePercent"]
                );
              }
              if (document.getElementById("registryGender").value == "Female") {
                document.getElementById("registryPercent").value =
                  response1.data.phpresult[0]["registryFemalePercent"];
                setRegistryPercent(
                  response1.data.phpresult[0]["registryFemalePercent"]
                );
              }

              document.getElementById("registryAmount").value =
                (response.data.phpresult[0]["areaSqmt"] *
                  response1.data.phpresult[0]["guideline"] *
                  document.getElementById("registryPercent").value) /
                100;
              const resAmt =
                (Number(areaSqft) *
                  Number(guidelineAmount) *
                  Number(registryPercent)) /
                100;
              // console.log(resAmt);

              setRegistryAmount(resAmt);
              // console.log(Number(registryAmount));

              if (response1.data.phpresult[0]["serviceType"] == "Lumpsum") {
                const amt = response1.data.phpresult[0]["serviceValue"];
                setServiceAmount(amt);
              }

              if (response1.data.phpresult[0]["serviceType"] == "PerSqmt") {
                const amt =
                  response1.data.phpresult[0]["serviceValue"] *
                  response.data.phpresult[0]["areaSqft"];
                setServiceAmount(amt);
              }

              if (response1.data.phpresult[0]["maintenanceType"] == "Lumpsum") {
                const amt = response1.data.phpresult[0]["maintenanceValue"];
                setMaintenanceAmount(amt);
              }

              if (response1.data.phpresult[0]["maintenanceType"] == "PerSqmt") {
                const amt =
                  response1.data.phpresult[0]["maintenanceValue"] *
                  response.data.phpresult[0]["areaSqft"];
                setMaintenanceAmount(amt);
              }

              if (response1.data.phpresult[0]["miscType"] == "Lumpsum") {
                const amt = response1.data.phpresult[0]["miscValue"];
                setMiscAmount(amt);
              }
              if (response1.data.phpresult[0]["miscType"] == "PerSqmt") {
                const amt =
                  response1.data.phpresult[0]["miscValue"] *
                  response.data.phpresult[0]["areaSqft"];
                setMiscAmount(amt);
              }

              const bankAmt = Number(guidelineAmount);
              setBankAmountPayable(bankAmt);
              setCashAmountPayable(0);
            }
          }
        }
      }
    } catch (error) {
      console.log("erorrrr");
    }
  };

  const enableDisPer = () => {
    if (document.getElementById("discountApplicable").value == "No") {
      document.getElementById("discountPercent").disabled = true;
      setNetAmount(totalAmount);
    } else {
      document.getElementById("discountPercents").disabled = false;
    }
  };
  const calNetAmt = () => {
    if (document.getElementById("discountApplicable").value == "Yes") {
      const per = document.getElementById("discountPercent").value;
      const netAmt =
        document.getElementById("areaSqft").value *
          document.getElementById("rateAreaSqft").value -
        (document.getElementById("areaSqft").value *
          document.getElementById("rateAreaSqft").value *
          per) /
          100;
      setNetAmount(netAmt);
    } else {
      const netAmt =
        document.getElementById("areaSqft").value *
        document.getElementById("rateAreaSqft").value;
      setNetAmount(netAmt);
    }
  };

  const SetRegisteryPer = () => {
    if (document.getElementById("registryGender").value == "Male") {
      document.getElementById("registryPercent").value =
        master[0].registryMalePercent;
      const registryPer = master[0].registryMalePercent;
      setRegistryPercent(registryPer);
    }
    if (document.getElementById("registryGender").value == "Female") {
      document.getElementById("registryPercent").value =
        master[0].registryFemalePercent;
      const registryPer = master[0].registryFemalePercent;
      setRegistryPercent(registryPer);
    }
  };

  // Changed in remove the areaSq property
  const resgistryAmt = () => {
    const val = document.getElementById("registryPercent").value;

    document.getElementById("registryAmount").value =
      (Number(guidelineAmount) * val) / 100;

    const resAmt = (Number(guidelineAmount) * Number(registryPercent)) / 100;

    setRegistryAmount(resAmt);
  };

  useEffect(() => {
    resgistryAmt();
  }, [registerygender]);

  // ===================================total=======================================
  const ConstAmt = () => {
    if (document.getElementById("constructionApplicable").value == "No") {
      document.getElementById("constructionContractor").disabled = true;
      document.getElementById("constructionAmount").disabled = true;

      const TotalAmtPay = Number(grandTotal);

      setTotalAmountPayable(TotalAmtPay);
    }

    if (document.getElementById("constructionApplicable").value == "Yes") {
      document.getElementById("constructionContractor").disabled = false;
      document.getElementById("constructionAmount").disabled = false;

      // console.log(constructionAmount);
      const TotalAmtPay = Number(grandTotal) + Number(constructionAmount);
      setTotalAmountPayable(TotalAmtPay);
    }

    const constructionApplicable = document.getElementById(
      "constructionApplicable"
    ).value;

    // if (constructionApplicable === "No") {
    //   setTotalAmountPayable(""); // Clear Total Amount Payable
    //   setCashAmountPayable(""); // Clear Cash Amount Payable
    // }
    // if (constructionApplicable === "Yes") {
    const totalPayable = Number(grandTotal) + Number(constructionAmount || 0);
    setTotalAmountPayable(totalPayable);

    // const cashPayable = Number(totalPayable) - Number(bankAmountPayable || 0);
    // setCashAmountPayable(cashPayable);
    // }
  };

  useEffect(() => {
    const cashPayable = Number(totalAmountPayable) - Number(bankAmountPayable);
    setCashAmountPayable(cashPayable);
  }, [totalAmountPayable, bankAmountPayable]);

  // ===============================Updata value of resPrecent & BankAmtPayable

  // Update bank amount payable whenever registry percent changes
  const handleRegistryPercentChange = (value) => {
    const percent = Number(value);
    setRegistryPercent(percent);

    if (guidelineAmount > 0) {
      const bankAmt = (guidelineAmount * percent) / 100;
      setBankAmountPayable(bankAmt);
      setCashAmountPayable();
    }
  };

  // Update registry percent whenever bank amount payable changes
  const handleBankAmountChange = (value) => {
    const bankAmt = Number(value);
    setBankAmountPayable(bankAmt);

    if (guidelineAmount > 0) {
      const percent = (bankAmt / guidelineAmount) * 100;
      setRegistryPercent(percent);
    }

    if (guidelineAmount > 0) {
      const percent = (bankAmt * 100) / guidelineAmount;
      setRegistry(percent);
      //   setRegistryPercent(percent);
    }
  };

  // Handle changes to Guideline Amount
  const handleGuidelineAmountChange = (value) => {
    const guideline = Number(value);
    setGuidelineAmount(guideline);

    // Recalculate Bank Amount Payable and Registry Percent based on the new Guideline Amount
    const bankAmt = (guideline * registry) / 100;
    setBankAmountPayable(bankAmt);

    const percent = (bankAmountPayable * 100) / guideline;
    setRegistryPercent(percent);
  };

  // Calculate cash amount payable whenever totalAmountPayable or bankAmountPayable changes
  // useEffect(() => {
  //   setCashAmountPayable(totalAmountPayable - bankAmountPayable);
  // }, [totalAmountPayable, bankAmountPayable]);

  // ==========================

  const calCashAmtPay = () => {
    const amt =
      document.getElementById("totalAmountPayable").value -
      document.getElementById("bankAmountPayable").value;

    // console.log(document.getElementById("totalAmountPayable").value);
    // console.log(document.getElementById("bankAmountPayable").value);

    // console.log(amt);
    setCashAmountPayable(amt);
  };

  useEffect(() => {
    loadProjects();
    loadContractor();
    loadBroker();
  }, []);

  useEffect(() => {
    ConstAmt();
  }, [contructionApplicable, constructionAmount, grandTotal]);

  const getGrandTotal = () => {
    // Ensure the variables are treated as numbers
    const total =
      Number(netAmount) +
      Number(registryAmount) +
      Number(maintenanceAmount) +
      Number(miscAmount) +
      Number(serviceAmount);
    setGrandTotal(total);
  };

  // If edit guideline amount to change registry amount
  useEffect(() => {
    const calculateRegistryAmount = () => {
      const percentage =
        registerygender === "Male"
          ? Number(master[0]?.registryMalePercent || 0)
          : registerygender === "Female"
            ? Number(master[0]?.registryFemalePercent || 0)
            : 0;

      const calculatedRegistryAmount =
        (Number(guidelineAmount) * percentage) / 100;
      setRegistryPercent(percentage);
      setRegistryAmount(calculatedRegistryAmount);
    };

    calculateRegistryAmount();
  }, [registerygender, guidelineAmount, master]);

  // ================================= sp code ===================

  // pratik and shreyash code

  // useEffect(() => {
  //   if (guidelineAmount && registryPercent) {
  //     const calculatedRegistryAmount =
  //       (Number(guidelineAmount) * Number(registryPercent)) / 100;
  //     setRegistryAmount(calculatedRegistryAmount);
  //   }
  // }, [areaSqft, guidelineAmount, registryPercent]);

  // useEffect(() => {
  //   // Ensure the values are treated as numbers
  //   const totalPayable =
  //     Number(grandTotal || 0) + Number(constructionAmount || 0);

  //   setTotalAmountPayable(totalPayable);
  // }, [grandTotal, constructionAmount]);

  useEffect(() => {
    loadProjects();
  }, []);

  //  Calculate Bank Amount Payable based on guidelineAmount
  useEffect(() => {
    if (guidelineAmount) {
      const bankPayable = Number(guidelineAmount);
      setBankAmountPayable(bankPayable);
    }
  }, [guidelineAmount]);

  useEffect(() => {
    if (totalAmountPayable) {
      calCashAmtPay();
    }
  }, [totalAmountPayable]);

  useEffect(() => {
    getGrandTotal();
    ConstAmt();
  }, [netAmount, registryAmount, serviceAmount, maintenanceAmount, miscAmount]);

  return (
    <Box p={4} width="100%" position={"relative"} bottom={"0rem"}>
      <Center pb={2}>
        <Heading fontSize={"22px"} position={"relative"} bottom={"1rem"}>
          New Booking
        </Heading>
      </Center>
      <br />
      <Box position={"relative"} bottom={"1rem"}>
        <form onSubmit={onAddBook}>
          <Grid templateColumns="repeat(4, 1fr)" gap={1}>
            <FormControl colSpan={1} isRequired>
              <FormLabel>Project Name</FormLabel>
              <Select
                id="projectName"
                name="state"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  loadBlocks(e.target.value);
                }}
                placeholder="Select Project"
              >
                {projectsData.map((project) => {
                  return (
                    <option
                      key={project.projectName}
                      value={project.projectName}
                    >
                      {project.projectName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Block Name</FormLabel>
              <Select
                id="blockName"
                name="state"
                value={blockName}
                onChange={(e) => {
                  setBlockname(e.target.value);
                  loadPlots(e.target.value);
                }}
                placeholder="Select Block"
              >
                {blockData.map((block) => {
                  return (
                    <option key={block.blockName} value={block.blockName}>
                      {block.blockName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Plot No</FormLabel>
              <Select
                id="plotNo"
                name="state"
                value={plotName}
                onChange={(e) => {
                  setPlotName(e.target.value);
                  onSelectPlot(e.target.value);
                }}
                placeholder="Select Plot No"
              >
                {plotData.map((plot) => {
                  return (
                    <option key={plot.plotNo} value={plot.plotNo}>
                      {plot.plotNo}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Plot Type</FormLabel>
              <Input id="plotType" value={myValue} type="text" required />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Name</FormLabel>
              <Input
                id="customerName"
                type="text"
                name="customerName"
                onChange={handleChangeValue}
                value={formData.customerName}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Address</FormLabel>
              <Input
                id="customerAddress"
                type="text"
                name="customerAddress"
                onChange={handleChangeValue}
                value={formData.customerAddress}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Contact</FormLabel>
              <Input
                id="customerContact"
                type="text"
                name="customerContact"
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Registry Gender</FormLabel>
              <Select
                id="registryGender"
                name="registryGender"
                onChange={(e) => {
                  setregisterygender(e.target.value);
                  SetRegisteryPer();
                }}
                required
              >
                <option value="" disabled>
                  Select Gender
                </option>
                {genders.map((gender) => (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Area Sqft</FormLabel>
              <Input
                id="areaSqft"
                type="text"
                value={areaSqft}
                name="areaSqft"
                required
                disabled={
                  userRight === "Manager" || userRight === "SalesPerson"
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Rate Per Sqft</FormLabel>
              <Input
                id="rateAreaSqft"
                type="text"
                value={rateAreaSqft}
                name="rateAreaSqft"
                //onChange={handleChange updateOnChange}
                required
                disabled={
                  userRight === "Manager" || userRight === "SalesPerson"
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Total Amount</FormLabel>
              <Input
                id="totalAmount"
                type="text"
                name="totalAmount"
                value={totalAmount}
                required
                bg={"yellow"}
                color={"black"}
                placeholder="0"
                disabled={
                  userRight === "Manager" || userRight === "SalesPerson"
                }
              />
            </FormControl>
            <Box gridColumn="span 1" />

            <FormControl id="discountApplicable" isRequired>
              <FormLabel>Discount Applicable</FormLabel>
              <Select
                id="discountApplicable"
                name="discountApplicable"
                value={discountApplicable}
                onChange={(e) => {
                  setDiscountApplicable(e.target.value);
                  enableDisPer();
                }}
                placeholder="Select an option"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Discount Percent</FormLabel>
              <Input
                onChange={(e) => {
                  setDiscountPercent(e.target.value);
                  calNetAmt();
                }}
                id="discountPercent"
                type="text"
                value={discountPercent}
                name="discountPercent"
                disabled={
                  discountApplicable === "No" ||
                  userRight === "Manager" ||
                  userRight === "SalesPerson"
                }
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Net Amount</FormLabel>
              <Input
                id="netAmount"
                type="text"
                name="netAmount"
                value={netAmount}
                onChange={(e) => {
                  setNetAmount(e.target.value);
                }}
                required
                bg={"yellow"}
                placeholder="0"
              />
            </FormControl>

            <Box gridColumn="span 1" />
            <FormControl>
              <FormLabel>Registry Amount</FormLabel>
              <Input
                id="registryAmount"
                type="number"
                name="registryAmount"
                required
                value={registryAmount}
                onChange={(e) => {
                  // setRegistryAmount(e.target.value)
                  SetRegisteryPer();
                }}
                placeholder="0"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Service Amount</FormLabel>
              <Input
                value={serviceAmount}
                id="serviceAmount"
                type="number"
                name="serviceAmount"
                onChange={(e) => {
                  setServiceAmount(e.target.value);
                }}
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Maintenance Amount</FormLabel>
              <Input
                value={maintenanceAmount}
                id="maintenanceAmount"
                type="number"
                name="maintenanceAmount"
                onChange={(e) => {
                  setMaintenanceAmount(e.target.value);
                }}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel>Misc Amount</FormLabel>
              <Input
                value={miscAmount}
                id="miscAmount"
                type="number"
                name="miscAmount"
                onChange={(e) => {
                  setMiscAmount(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Grand Total</FormLabel>
              <Input
                id="grandTotal"
                placeholder="0"
                type="number"
                name="grandTotal"
                value={grandTotal}
                bg={"yellow"}
                // onChange={(e) => {
                //   grandTotal(e.target.value);
                // }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Construction Applicable</FormLabel>
              <Select
                id="constructionApplicable"
                value={contructionApplicable}
                name="constructionApplicable"
                onChange={(e) => {
                  setContructionApplicable(e.target.value);
                }}
                required
              >
                <option value="">Select the option</option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Construction Contractor</FormLabel>

              <Select
                id="constructionContractor"
                type="text"
                name="constructionContractor"
                value={contractorName}
                onChange={(e) => {
                  setContractorName();
                }}
                placeholder="Select Contactor"
              >
                {contractorData.map((block) => {
                  return (
                    <option
                      key={block.contractorName}
                      value={block.contractorName}
                    >
                      {block.contractorName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl id="constructionAmount" isRequired>
              <FormLabel>Construction Amount</FormLabel>
              <Input
                type="text"
                id="constructionAmount"
                name="constructionAmount"
                value={constructionAmount}
                onChange={(e) => {
                  setConstructionAmount(e.target.value);
                  setTotalAmountPayable();
                }}
                placeholder="Enter construction amount"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Total Amount Payable</FormLabel>
              <Input
                value={totalAmountPayable}
                id="totalAmountPayable"
                type="number"
                name="totalAmountPayable"
                onChange={(e) => {
                  setTotalAmountPayable(e.target.value);
                  calCashAmtPay();
                }}
                placeholder="0"
                bg={"yellow"}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Guideline Amount</FormLabel>
              <Input
                value={guidelineAmount == "" ? "" : guidelineAmount}
                id="guidelineAmount"
                type="number"
                name="guidelineAmount"
                onChange={(e) => {
                  setGuidelineAmount(e.target.value);
                  handleGuidelineAmountChange(e.target.value);
                }}
                placeholder="0"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Registry Percent</FormLabel>
              <Input
                id="registryPercent"
                type="number"
                name="registryPercent"
                // value={Number(
                //   ((registry == "" ? "" : registry) || 0).toFixed(2)
                // )}
                value={Number((registry === "" ? "" : registry).toFixed(2))}
                onChange={(e) => {
                  const value = parseFloat(e.target.value) || 0;

                  setRegistry(value);
                  handleRegistryPercentChange(value);
                }}
              />
            </FormControl>

            <Box gridColumn="span 1" />
            <Box
              display="grid"
              gridAutoFlow="column"
              position={"absolute"}
              top={"100%"}
              gap={"2rem"}
            >
              <FormControl>
                <FormLabel>Bank Amount Payable</FormLabel>
                <Input
                  id="bankAmountPayable"
                  type="number"
                  value={bankAmountPayable || ""}
                  // readOnly
                  onChange={(e) => {
                    setBankAmountPayable(e.target.value);
                    handleBankAmountChange(e.target.value);
                  }}
                  bg={"yellow"}
                  placeholder="0"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Cash Amount Payable</FormLabel>
                <Input
                  id="cashAmountPayable"
                  type="number"
                  value={cashAmountPayable}
                  name="cashAmountPayable"
                  // onChange={handleChange}

                  bg={"yellow"}
                  placeholder="0"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Booking Date</FormLabel>
                <Input
                  id="bookingDate"
                  type="date"
                  name="bookingDate"
                  required
                  // value={formData.d}
                  defaultValue={new Date().toISOString().slice(0, 10)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Broker</FormLabel>

                <Select
                  id="broker"
                  type="text"
                  name="broker"
                  //value={broker}
                  // onChange={(e) => {
                  //     setBroker();
                  // }}
                  placeholder="Select "
                >
                  {brokerData.map((block) => {
                    return (
                      <option key={block.brokerName} value={block.brokerName}>
                        {block.brokerName}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl id="remarks" isRequired>
                <FormLabel>Remarks</FormLabel>
                <Textarea
                  name="remarks"
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  rows={2}
                  w="320px"
                  resize="none"
                />
              </FormControl>

              <Button
                colorScheme="blue"
                type="button"
                mt={8}
                onClick={onAddBook}
                isDisabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </Grid>
        </form>
      </Box>
      <br />
      <br />
      <br />
      <br />
    </Box>
  );
};
export default NewBooking;
